<template>
  <ContentWrapper title="">
    <template v-slot:title>Profile</template>
    <Container :style="{ position: 'relative' }" class="profile-container">
      <router-view></router-view>
    </Container>
    <Container style="margin-top:1rem">
       <div class="first-wrapper">
        <h3>Gameplay (History / Performance)</h3>
        <div class="search-container">
          <font-awesome-icon class="icon" icon="search" />
          <input
            class="input-field"
            type="text"
            placeholder="Search: e.g date, game name"
            @keyup="search"
            v-model="searchValue"
          />
        </div>
      </div>
      <GameplayLeaderboard @toggleOrder="setOrder"/>
    </Container>
  </ContentWrapper>
</template>

<script>

import GameplayLeaderboard from "./components/GameplayLeaderboard"
import {eventBus} from '@/main.js';


export default {
  components: {
    GameplayLeaderboard
  },
  data() {
    return {
      user: {
        avatar_arr: []
      },
      searchValue: '',
      timeoutSearch: null,
      params:{
        order: 'DATE',
      },
      orderParamsList: ['DATE','GAME_TYPE','GAME_NAME','ROI','RANKING']
    };
  },

  methods: {
    search(){
      clearTimeout(this.timeoutSearch);
      
      var vm = this;
      this.params.q = this.searchValue;

      this.timeoutSearch = setTimeout(async function () {
        await vm.callStoreLeaderboardAPI();
      }, 1000);
    },
    async setOrder(index){
      if(this.params?.order == this.orderParamsList[index]){
        this.params.asc == 1 ? this.params.asc = 0 : this.params.asc = 1;
      }else{
        this.params.order = this.orderParamsList[index];
      }
     
      await this.callStoreLeaderboardAPI();
    },
    async callStoreLeaderboardAPI(){
      try {
        eventBus.$emit('loader',true);
        await this.$store.dispatch('callPersonalLeaderboardList',{params: this.params});
        eventBus.$emit('loader',false);
      } catch (error) {
        eventBus.$emit('loader',false);
        console.log(error);
      }
    }
  },
};
</script>

<style lang="scss" scoped>

.content-container{
  @media screen and (max-width:450px) {
    height: auto;
  }
}

.content-wrapper{
  @media screen and (max-width:450px) {
    overflow: auto;
  }
}


.first-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  @include respond(tab-port) {
    flex-direction: column;
    gap: 1rem;
  }
  .search-container {
    display: flex;
    width: 50%;
    border: 1px solid black;
    @include respond(tab-port){
      width: 100%;
    }
    .icon {
      margin: auto 0.5rem;
      color: #efefef;
    }
    .input-field {
      padding: 0.625rem;
      width: 100%;
      border: none;
    }
  }
}
</style>
