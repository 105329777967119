<template>
  <section class="user-wrapper">
    <router-view></router-view>
  </section>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>
  .user-wrapper{
    background: var(--black00070D) url('../../assets/images/abstract_background.png') no-repeat fixed;
    background-position: right bottom;
    min-height: 100vh;
    max-width: 100vw;
    display: grid;
    place-items: center;
  }
</style>